<template>
  <div>
    <coming-soon title='Voting Coming Soon' />
  </div>
</template>

<script>
import ComingSoon from '@/components/apsr2022/ComingSoon.vue'

export default {
  name: 'Voting',
  components: {
    ComingSoon,
  },
}
</script>
